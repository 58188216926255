import { useLayout } from '../../contexts/LayoutContext';
import './ErrorTooltip.css';

const ErrorTooltip = ({ message, xPlacement, yPlacement }) => {
  const { activeLayout } = useLayout();
  return (
    <p className={`error-tooltip ${xPlacement} ${yPlacement} ${activeLayout}`}>{message}</p>
  );
};

export default ErrorTooltip;
