import FileList from '../Explorer/FileList/FileList';
import BreadCrumb from '../Explorer/BreadCrumb/BreadCrumb';
import Toolbar from '../Explorer/Toolbar/Toolbar';

const ContentList = ({
  setDraggedItem,
  contentSelected,
  triggerAction,
  onCreateFolder,
  onRename,
  onFileOpen,
  onLayoutChange,
  onRefresh,
  enableFilePreview,
}) => (
  <>
    <div>
      <Toolbar
        onLayoutChange={onLayoutChange}
        onRefresh={onRefresh}
        triggerAction={triggerAction}
        allowCreateFolder
        allowUploadFile
      />
    </div>
    <div className='content-view-container'>
      <div className='folderCrumb'>
        <BreadCrumb />
      </div>
      <FileList
        onCreateFolder={onCreateFolder}
        onRename={onRename}
        onFileOpen={onFileOpen}
        onRefresh={onRefresh}
        enableFilePreview={enableFilePreview}
        triggerAction={triggerAction}
        setDraggedItem={setDraggedItem}
      />
      {/* {showContentData && contentImages && contentImages.map((contentImage, i) => (
                <div className='content_thumbnails' key={`img_${i}`}>
                  <Image
                    src={contentImage} // contentImage.default for linux
                    thumbnail
                    alt="image"
                    draggable={true}
                    onDragStart={(e) => {
                      // eslint-disable-next-line
                      setDraggedItem({
                        id: i + 1,
                        name: 'Display Stand.glb',
                        src: e.target.src,
                        height: 100,
                        width: 100,
                      });
                    }}
                  />
                  <p>Display Stand.glb</p>
                </div>
              ))} */}
    </div>
  </>
);

export default ContentList;
