import React from 'react';

const ModelViewer = ({ src, alt }) => (
  <model-viewer
    src={src}
    alt={alt}
    auto-rotate
    camera-controls
    style={{ width: '100%', height: '100%' }}
  />
);

export default ModelViewer;
