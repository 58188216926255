import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { objectDelete } from '../../../../services/SolutionService';

const DeleteObjectModal = ({
  showDeleteModal, handleShowDelete, selectedObject, objects, setObjects,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveObject = async () => {
    try {
      setIsLoading(true);
      const response = await objectDelete({ id: selectedObject?.id });
      if (response.success) {
        const remainingObjects = removeObjectFromTree(objects, selectedObject.id);
        setObjects(remainingObjects);
        setIsLoading(false);
        handleShowDelete();
        toast.success(response.message);
      } else {
        setIsLoading(false);
        handleShowDelete();
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      handleShowDelete();
      toast.error(error.message);
    }
  };

  const removeObjectFromTree = (tree, idToRemove) => tree
    .map((node) => {
      if (node.items) {
        // eslint-disable-next-line no-param-reassign
        node.items = removeObjectFromTree(node.items, idToRemove);
      }
      return node;
    })
    .filter((node) => node.id !== idToRemove);

  useEffect(() => {
    setShow(showDeleteModal);
  }, [showDeleteModal]);

  return (
    <>
      <Modal className="maxWidth" show={show} onHide={handleShowDelete}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 modal-title h4">Delete Object</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure that you want to remove this item from the canvas?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" variant="secondary" onClick={handleShowDelete}>
            No
          </Button>
          <Button variant="primary" onClick={handleRemoveObject} disabled={isLoading}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">Yes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteObjectModal;
