import { useState } from 'react';
import {
  Button, ButtonGroup, Collapse,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Folder from './Objects/Folder';
import File from './Objects/File';
import DeleteObjectModal from './Objects/DeleteObjectModal';

// const folderStructureData = [
//   {
//     id: 1,
//     name: 'Store Front',
//     isFolder: true,
//     src: storefront,
//     path: '/Store Front',
//     items: [],
//   },
//   {
//     id: 2,
//     name: 'Entrance floor',
//     isFolder: true,
//     src: entrancefloor,
//     path: '/Entrance floor',
//     items: [
//       {
//         id: 21,
//         name: 'Interior',
//         isFolder: true,
//         src: interior,
//         path: '/Entrance floor/Interior',
//         items: [],
//       },
//       {
//         id: 22,
//         name: 'Products',
//         isFolder: true,
//         src: products,
//         path: '/Products',
//         items: [],
//       },
//       {
//         id: 23,
//         name: 'Apps',
//         isFolder: true,
//         src: apps,
//         path: '/Apps',
//         items: [],
//       },
//       {
//         id: 24,
//         name: 'Others',
//         isFolder: true,
//         src: others,
//         path: '/Others',
//         items: [],
//       },
//     ],
//   },
//   {
//     id: 3,
//     name: 'Avtars',
//     isFolder: true,
//     src: avatars,
//     path: '/Avtars',
//     items: [],
//   },
//   {
//     id: 4,
//     name: 'Tablet',
//     isFolder: true,
//     src: tablet,
//     path: '/Tablet',
//     items: [],
//   },
// ];

const ObjectList = ({
  objects,
  setObjects,
  selectedObject,
  setSelectedObject,
}) => {
  const [open, setOpen] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleOpenObject = () => {
    setOpen(!open);
  };

  const handleSelectedItem = (item) => {
    setSelectedObject(item);
  };

  const handleShowDelete = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleShowDeleteObject = async () => {
    if (selectedObject && selectedObject?.id) {
      if (selectedObject.isFolder) {
        toast.error('You can not remove this item. Please select another item');
      } else {
        handleShowDelete();
      }
    } else {
      toast.error('Please select atleast one object.');
    }
  };

  return (
    <>
      <div className="properties">
        <Button
          onClick={handleOpenObject}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          Objects
        </Button>
      </div>
      <div className="plusIcon text-end">
        <ButtonGroup className="px-3 py-2">
          {/* <Button><i className="bi bi-plus-circle-dotted"></i></Button> */}
          <Button onClick={handleShowDeleteObject}><i className="bi bi-trash"></i></Button>
        </ButtonGroup>
      </div>
      <div className="productObject scrollbarCard">
        <Collapse in={open}>
          <div id="example-collapse-text" style={{ overflow: 'auto', minHeight: '100px' }}>
            <div className="sidebarCard">
              {objects.map((item) => (item.isFolder ? <Folder key={`asset_${item.id}`} item={item} selectedObject={selectedObject} handleSelectedItem={handleSelectedItem} /> : <File key={item.id} item={item} />))}
            </div>
          </div>
        </Collapse>
      </div>
      <DeleteObjectModal
        showDeleteModal={showDeleteModal}
        handleShowDelete={handleShowDelete}
        selectedObject={selectedObject}
        objects={objects}
        setObjects={setObjects}
      />
    </>
  );
};

export default ObjectList;
