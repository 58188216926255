import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdMoreHoriz } from 'react-icons/md';
import { useFileNavigation } from '../../../../contexts/FileNavigationContext';
import useDetectOutsideClick from '../../../../hooks/useDetectOutsideClick';
import './BreadCrumb.css';

const BreadCrumb = () => {
  const [folders, setFolders] = useState([]);
  const [hiddenFolders, setHiddenFolders] = useState([]);
  const [hiddenFoldersWidth, setHiddenFoldersWidth] = useState([]);
  const [showHiddenFolders, setShowHiddenFolders] = useState(false);

  const { currentPath, setCurrentPath } = useFileNavigation();
  const breadCrumbRef = useRef(null);
  const foldersRef = useRef([]);
  const moreBtnRef = useRef(null);
  const popoverRef = useDetectOutsideClick(() => {
    setShowHiddenFolders(false);
  });

  useEffect(() => {
    setFolders(() => {
      let path = '';
      return (currentPath || '')
        .split('/')
        .filter((item) => item)
        .map((item) => {
          if (item !== '') {
            path += `/${item}`;
          }
          return {
            name: item || 'Home',
            path: item === '' ? item : path,
          };
        });
    });
    setHiddenFolders([]);
    setHiddenFoldersWidth([]);
  }, [currentPath]);

  const switchPath = (path) => {
    setCurrentPath(path);
  };

  const getBreadCrumbWidth = useCallback(() => {
    const containerWidth = breadCrumbRef.current.clientWidth;
    const containerStyles = getComputedStyle(breadCrumbRef.current);
    const paddingLeft = parseFloat(containerStyles.paddingLeft);
    const moreBtnGap = hiddenFolders.length > 0 ? 1 : 0;
    const flexGap = parseFloat(containerStyles.gap) * (folders.length + moreBtnGap);
    return containerWidth - (paddingLeft + flexGap);
  }, [folders.length, hiddenFolders.length]);

  const checkAvailableSpace = useCallback(() => {
    const availableSpace = getBreadCrumbWidth();
    const remainingFoldersWidth = foldersRef.current.reduce((prev, curr) => {
      if (!curr) return prev;
      return prev + curr.clientWidth;
    }, 0);
    const moreBtnWidth = moreBtnRef.current?.clientWidth || 0;
    return availableSpace - (remainingFoldersWidth + moreBtnWidth);
  }, [getBreadCrumbWidth]);

  const isBreadCrumbOverflowing = useCallback(() => breadCrumbRef.current.scrollWidth > breadCrumbRef.current.clientWidth, []);

  useEffect(() => {
    if (isBreadCrumbOverflowing()) {
      const hiddenFolder = folders[1];
      const hiddenFolderWidth = foldersRef.current[1]?.clientWidth;
      setHiddenFoldersWidth((prev) => [...prev, hiddenFolderWidth]);
      setHiddenFolders((prev) => [...prev, hiddenFolder]);
      setFolders((prev) => prev.filter((_, index) => index !== 1));
    } else if (hiddenFolders.length > 0 && checkAvailableSpace() > hiddenFoldersWidth.at(-1)) {
      const newFolders = [folders[0], hiddenFolders.at(-1), ...folders.slice(1)];
      setFolders(newFolders);
      setHiddenFolders((prev) => prev.slice(0, -1));
      setHiddenFoldersWidth((prev) => prev.slice(0, -1));
    }
  }, [isBreadCrumbOverflowing, checkAvailableSpace, folders, hiddenFolders, hiddenFoldersWidth]);

  return (
    <div className='bread-crumb-container'>
      <div className='breadcrumb' ref={breadCrumbRef}>
        <div style={{ display: 'contents' }} onClick={() => setCurrentPath('')}>
          <span className={'folder-name'}>Content {currentPath !== '' && '/ '}</span>
        </div>
        {folders.map((folder, index) => (
          <div key={index} style={{ display: 'contents' }}>
            {index > 0 && '/  '}
            <span
              className={`folder-name ${(folders.length - 1) === index ? 'curr-folder' : ''}`}
              onClick={() => switchPath(folder.path)}
              ref={(el) => {
                foldersRef.current[index] = el;
              }}
            >
              {folder.name}
            </span>
            {hiddenFolders?.length > 0 && index === 0 && (
              <button
                className='folder-name folder-name-btn'
                onClick={() => setShowHiddenFolders(true)}
                ref={moreBtnRef}
                title='Show more folders'
              >
                <MdMoreHoriz size={22} className='hidden-folders' />
              </button>
            )}
          </div>
        ))}
      </div>

      {showHiddenFolders && (
        <ul ref={popoverRef.ref} className='hidden-folders-container'>
          {hiddenFolders.map((folder, index) => (
            <li
              key={index}
              onClick={() => {
                switchPath(folder.path);
                setShowHiddenFolders(false);
              }}
            >
              {folder.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BreadCrumb;
