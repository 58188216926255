import React, { useEffect, useRef } from 'react';
import { Rect, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';

const ModelViewerKonva = ({
  modelProps, isSelected, onSelect, onChange,
}) => {
  const modelRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([modelRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      {/* Draggable Rect to handle Konva interactions */}
      <Rect
        x={modelProps.x}
        y={modelProps.y}
        width={modelProps.width}
        height={modelProps.height}
        offsetX={modelProps.width / 2}
        offsetY={modelProps.height / 2}
        fill="transparent"
        draggable
        ref={modelRef}
        onClick={onSelect}
        onTap={onSelect}
        onDragMove={(e) => {
          const node = e.target;
          const newPos = { x: node.x(), y: node.y() };
          onChange({
            ...modelProps,
            ...newPos,
          });
        }}
        onTransformEnd={(e) => {
          const node = modelRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...modelProps,
            x: node.x(),
            y: node.y(),
            width: Math.max(5, modelProps.width * scaleX),
            height: Math.max(5, modelProps.height * scaleY),
          });
        }}
      />
      {/* 3D Model Viewer */}
      <Html>
        <div
          style={{
            position: 'absolute',
            left: modelProps.x - modelProps.width / 2,
            top: modelProps.y - modelProps.height / 2,
            width: modelProps.width,
            height: modelProps.height,
            pointerEvents: isSelected ? 'auto' : 'none', // Prevent interfering with Konva events
          }}
        >
          <model-viewer
            src={modelProps.src}
            alt={modelProps.alt || '3D Model'}
            camera-controls
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </Html>
      {isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default ModelViewerKonva;
