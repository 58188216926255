import './Loader.css';
import ReactLoading from 'react-loading';

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div className='fm-loader'>
        <ReactLoading color='#e7e7e7' type='spokes' height={38} width={38} />
      </div>
    );
  }

  return null;
};

export default Loader;
