import {
  BrowserRouter, Navigate, Routes, Route,
} from 'react-router-dom';

// Layouts
import AuthLayout from '../layouts/AuthLayout';
import MerchantLayout from '../layouts/MerchantLayout';
import VirtualLayout from '../layouts/VirtualLayout';
// Auth
import Login from '../pages/Auth/Login';
import Logout from '../pages/Auth/Logout';
import Signup from '../pages/Auth/Signup';
import ResendVerifyEmail from '../pages/Auth/ResendVerifyEmail';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import StoreList from '../pages/Auth/StoreList';
import VerifyEmail from '../pages/Auth/VerifyEmail';
import NotFound from '../pages/NotFound';
// Merchant
import Profile from '../pages/Merchant/Profile';
import SolutionList from '../pages/Merchant/virtual/SolutionList';
import SolutionEdit from '../pages/Merchant/virtual/SolutionEdit';
import SolutionView from '../pages/Merchant/virtual/SolutionView';
import AuthProvider from '../hooks/AuthProvider';

function App() {
  return (
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <AuthProvider>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="" element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="signup" element={<Signup />} />
            <Route path="resend/verify-email" element={<ResendVerifyEmail />} />
            <Route path="forgot" element={<ForgotPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="store/list" element={<StoreList />} />
            <Route path="verify-email/:token" element={<VerifyEmail />} />
            <Route path="not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/not-found" />} />
            <Route path="/solution/:solutionId" element={<SolutionView />} />
          </Route>
          <Route path="merchant" element={<MerchantLayout />}>
            <Route path="profile" element={<Profile />} />
          </Route>
          <Route path="virtual" element={<VirtualLayout />}>
            <Route path="solution" element={<SolutionList />} />
            <Route path='solution/edit/:solutionId' element={<SolutionEdit />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
