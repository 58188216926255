import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const SolutionView = () => {
  const { solutionId } = useParams();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Getting solution id here: ', solutionId);
  }, [solutionId]);

  return (
    <>
      <h1>Solution</h1>
    </>
  );
};

export default SolutionView;
