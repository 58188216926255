import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FaBrush } from 'react-icons/fa6';
import { Image } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import productIcon from '../../../assets/images/virtual/productIcon.png';
import appIcon from '../../../assets/images/virtual/appIcon.png';
import getParentPath from '../../../utils/getParentPath';
import FolderTree from '../Explorer/FolderTree/FolderTree';
import { useFiles } from '../../../contexts/FilesContext';
import { useFileNavigation } from '../../../contexts/FileNavigationContext';
import folderIcon from '../../../assets/images/virtual/folderIcon.png';

const MyAssetLibrary = ({
  optionSelected, handleShow, contentSelected,
}) => {
  const [foldersTree, setFoldersTree] = useState([]);
  const { files } = useFiles();
  const { currentPath, setCurrentPath } = useFileNavigation();

  const handleShowClick = (option, data = {}) => {
    handleShow(option, data);
    option !== 1 && setCurrentPath(''); // Reset Path
  };

  const createChildRecursive = useCallback((path, foldersStruct) => {
    if (!foldersStruct[path]) return []; // No children for this path (folder)

    return foldersStruct[path]?.map((folder) => ({
      ...folder,
      subDirectories: createChildRecursive(folder.path, foldersStruct),
    }));
  }, []);

  const isContentActive = useMemo(() => optionSelected === 1 && currentPath === '', [optionSelected, currentPath]);
  const [isContentOpen, setIsContentOpen] = useState(false);

  useEffect(() => {
    if (currentPath !== '') {
      setIsContentOpen(true);
    }
  }, [currentPath]);

  useEffect(() => {
    if (Array.isArray(files)) {
      const folders = files.filter((file) => file.isDirectory);
      // Grouping folders by parent path
      const foldersStruct = Object.groupBy(folders, ({ path }) => getParentPath(path));
      setFoldersTree(() => {
        const rootPath = '';
        return createChildRecursive(rootPath, foldersStruct);
      });
    }
  }, [files, createChildRecursive]);

  return (
    <>
      <div className="fileExplorer">
        <h6 key="my_assets" className="text-white mb-0 assetsHeading">My Assets Library</h6>
        <div className="foldertree pt-3">
          <div className={`sb-folders-list-item ${isContentActive ? 'active-list-item' : ''} ${foldersTree?.length === 0 ? 'no-folder-content' : ''}`} onClick={() => {
            handleShowClick(1);
            setCurrentPath('');
          }}>
            {
              foldersTree?.length > 0
              && <span onClick={(e) => {
                e.stopPropagation();
                setIsContentOpen((prev) => !prev);
              }} className={`${isContentOpen ? 'folder-rotate-down' : 'folder-rotate-right'}`}>
                <i className='bi bi-chevron-right'></i>
              </span>
            }
            <div className='sb-folder-details'>
              <Image src={folderIcon} width={17} height={14} alt='folder' />
              <span className='sb-folder-name' title={'Content'}>
                Content
              </span>
            </div>
          </div>
          <Collapsible open={isContentOpen}>
            <div className='folder-collapsible'>
              {foldersTree?.map((folder, index) => <FolderTree key={index} folder={folder} handleShowClick={handleShowClick} />)}
            </div>
          </Collapsible>
          <p
            key="products"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleShowClick(2)}
            className={optionSelected === 2 ? 'selected' : ''}
          >
            <Image src={productIcon} width={17} height={17} alt='product' /> Products
          </p>
          <p
            key="apps"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleShowClick(3)}
            className={optionSelected === 3 ? 'selected' : ''}
          >
            <Image src={appIcon} width={17} height={18} alt='app' /> Apps
          </p>
          <p
            key="themes"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleShowClick(4)}
            className={optionSelected === 4 ? 'selected' : ''}
          >
            <FaBrush size={16} color='gold' className='me-1' /> Themes
          </p>
        </div>
      </div>
    </>
  );
};

export default MyAssetLibrary;
