import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { Image } from 'react-bootstrap';
import folderIcon from '../../../../assets/images/virtual/folderIcon.png';
import { useFileNavigation } from '../../../../contexts/FileNavigationContext';
import './foldertree.css';

const FolderTree = ({ folder, handleShowClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { currentPath, setCurrentPath } = useFileNavigation();

  const handleFolderSwitch = () => {
    setIsActive(true);
    setCurrentPath(folder.path);
    handleShowClick(1);
  };

  const handleCollapseChange = (e) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    // Setting isActive to a folder if its path matches currentPath
    setIsActive(currentPath === folder.path);

    // Auto expand parent folder if its child is accessed via file navigation
    // Explanation: Checks if the current folder's parent path matches with any folder path i.e. Folder's parent
    // then expand that parent.
    const currentPathArray = currentPath.split('/');
    // splits with '/' and pops to remove last element to get current folder's parent path
    currentPathArray.pop();
    const currentFolderParentPath = currentPathArray.join('/');
    if (currentFolderParentPath === folder.path) {
      setIsOpen(true);
    }
    //
  }, [currentPath, folder.path]);

  if (folder.subDirectories.length > 0) {
    return (
      <>
        <div
          className={`sb-folders-list-item ${isActive ? 'active-list-item' : ''}`}
          onClick={handleFolderSwitch}
        >
          <span onClick={handleCollapseChange} className={`${isOpen ? 'folder-rotate-down' : 'folder-rotate-right'}`}>
            <i className='bi bi-chevron-right'></i>
          </span>
          <div className='sb-folder-details'>
            {isOpen || isActive ? (
              <Image src={folderIcon} width={17} height={14} alt='folder' />
            ) : (
              <Image src={folderIcon} width={17} height={14} alt='folder' />
            )}
            <span className='sb-folder-name' title={folder.name}>
              {folder.name}
            </span>
          </div>
        </div>
        <Collapsible open={isOpen}>
          <div className='folder-collapsible'>
            {folder.subDirectories.map((item, index) => (
              <FolderTree key={index} folder={item} handleShowClick={handleShowClick} />
            ))}
          </div>
        </Collapsible>
      </>
    );
  }

  return (
    <div
      className={`sb-folders-list-item ${isActive ? 'active-list-item' : ''}`}
      onClick={handleFolderSwitch}
    >
      <span className='non-expanable'></span>
      <div className='sb-folder-details'>
        {isActive ? (
          <Image src={folderIcon} width={17} height={14} alt='folder' />
        ) : (
          <Image src={folderIcon} width={17} height={14} alt='folder' />
        )}
        <span className='sb-folder-name' title={folder.name}>
          {folder.name}
        </span>
      </div>
    </div>
  );
};

export default FolderTree;
